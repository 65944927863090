$small: 900px;
$medium: 1900px;
$large: 2560px;
$curved: 3440px;

.floating-metadata-container {
  position: relative;
  top: 55px;
  z-index: 10;
  width: 400px;
  background-color: #ffffff;
  font-size: small;
  padding: 1rem;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 0 0 0.25rem rgb(0 0 0 / 15%);
  overflow-x: hidden;
  margin-bottom: auto;
  margin-right: 1rem;
  max-height: calc(100vh - 186px);

  .metadata-header {
    display: flex;
    flex-direction: row;
    justify-content: right;
    button {
      margin: 0.1rem;
    }
  }
  .comments-accordian {
    max-height: 300px;
    width: 110%;
    overflow-y: scroll;
  }

  .add-comment-button {
    margin-top: 5%;
  }

  .metadata-button-group {
    .ap-button {
      margin: 0.2rem;
    }
    .add-and-vote {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .court-case-section-wrapper {
    width: 100%;
    ul {
      padding: 0;
    }
    .ap-list {
      background-color: transparent;
    }
    .ap-accordion-group-container {
      .ap-accordion {
        .ap-accordion-toggle {
          padding: 0;
          justify-content: flex-start;
          .ap-accordion-title {
            width: unset;
          }
        }
        width: 100%;
        .ap-accordion-content {
          padding: 0;
        }
      }
    }
  }

  .comments-wrapper {
    padding: 0.2rem;

    .comments {
      padding: 0.3rem;
      .comments-detail {
        .comments-info .comments-time {
          font-size: 14px;
        }
        .comments-content {
          font-size: 14px;
        }
      }
    }
    .comments-images {
      display: none !important;
    }
    .comments-likes {
      display: none !important;
    }

    .loader-wrapper {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      backdrop-filter: blur(3px);
    }
    .loader {
      display: block;
      position: relative;
      left: 50%;
      top: 50%;
      width: 200px;
      height: 200px;
      margin: -50px 0 0 -50px;
    }
  }
}

.floating-metadata-container.hidden {
  visibility: collapse;
}

.floating-metadata-container.expanded {
  min-width: 600px;
  max-width: 50%;
  z-index: 2;
  background: white;
}

.add-connection-modal {
  overflow: visible;
  .ap-modal-body {
    overflow: visible !important;
  }
  .connection-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }
  .ap-dropdown-overlay {
    overflow: visible;
    display: block;
  }
  .ap-dropdown-list {
    position: relative;
    z-index: 100001;
  }
  .add-connection-footer {
    display: flex;
    flex-direction: row;

    .ap-button {
      margin: 0 0.2rem;
    }
  }
}
