$small: 900px;
$medium: 1900px;
//$large:1920;
$curved: 3440px;

.floating-menu-container {
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 365px);
  z-index: 1;
  padding: 0rem 1rem;

  .view-selector {
    margin: 0.5rem 0;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
    width: 192px;
  }
}

.filter-container {
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 1rem 0.5rem;
  width: 350px;
  max-height: calc(100vh - 150px);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);

  .filter-activate-all-filters {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 0.5rem 0.5rem 0.2rem 0;
    width: 100%;
  }
  .filter-content {
    z-index: 2;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .filter-section {
      margin: 5px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      position: relative;

      .filter-select {
        width: 260px;
      }
    }

    .filter-date {
      display: flex;
      flex-direction: row;
      width: 260px;
      box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
      border-radius: var(--border-radius-2);
      height: 48px;
      padding: 0.5rem;
      color: var(--color-text-light);
      align-items: flex-end;

      .date-text {
        color: var(--color-text-light);
      }

      .filter-date-title {
        position: absolute;
        left: 8px;
        top: var(--spacing-7);
        max-width: calc(100% - var(--spacing-5));
        height: 0.75rem;
        line-height: 0.75rem;
        font-size: 0.75rem;
        color: var(--color-text-light);
        pointer-events: none;
      }
    }
  }

  .a-toggle {
    padding: 0 5px;
  }

  .select-toggle {
    width: 100%;
  }

  .a-calendar {
    min-width: 233px;
  }

  .a-date-picker-header {
    background: unset;
    width: 100%;

    .a-date-picker-header-date-input,
    .a-date-picker-header-date-input::placeholder,
    .a-calendar-pick-right-icon {
      color: #9e9e9e;
    }

    .a-date-picker-header-date-input::placeholder {
      position: absolute;
      left: 2.25rem;
      top: 0.625rem;
    }

    .a-date-picker-header-date-input {
      padding-left: 2.25rem;
    }

    .react-daterange-picker-button {
      left: 0.625rem;
      right: unset;
    }
  }

  .react-daterange-picker {
    width: 100%;
  }
}

#filterLogicSwitch.ap-switch .ap-switch-btn.ap-switch-checked {
  background-color: #d04a02 ;

}
#filterLogicSwitch.ap-switch .ap-switch-btn {
  background-color: #d04a02 ;
}
.ap-search-input .ap-search-after-icon.delete-icon.showDeleteIcon {
  cursor: pointer;
}

.ap-search-input .ap-search-after-icon.delete-icon {
  visibility: hidden;
}

.ap-switch {
  margin: 0 0.5rem;
}

.graph-legend-content {
  position: absolute;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  z-index: -1;
  transition: all 0.3s ease-in;

  .graph-legend-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.3rem;

    .graph-legend-button {
      position: relative;
      padding: 0.5rem;
      right: 0;
      background-color: #ffffff;
      transition: all 0.3s ease-in;
    }

    .graph-legend-button-popout {
      right: -47px;
      z-index: 5;
      border-top-right-radius: var(--border-radius-2);
      border-bottom-right-radius: var(--border-radius-2);
      box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
    }
  }
  .graph-legend-items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .graph-legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.1rem 0.3rem;
    width: 180px;

    .graph-legend-dot {
      height: 1.2rem;
      width: 1.2rem;
      margin: 0.1rem 0.3rem 0.1rem 0.1rem;
      border-radius: 50%;
      display: inline-block;
    }

    .graph-legend-arrow {
      transform: rotate(90deg);
      display: inline-block;
    }
  }
}

.graph-legend-hidden {
  right: 1px;
}

.graph-legend-show {
  right: -200px;
}

.ap-option-item .ap-option-left .ap-option-label {
  white-space: normal;
}

.ap-option-item {
  height: auto;
}
