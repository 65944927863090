
@import '../node_modules/@appkit4/styles/themes/appkit.orange.min.css'; 

@import '../node_modules/@appkit4/styles/scss/mixin';
@import '../node_modules/sass-rem/_rem.scss';

body, html {
  height: 100%;
  overflow: hidden;
    background-color: #f5f5f5; }  

    bodym {
      height: 100%;
    }

  #root {
    height: 100%;
  }
  
  .nav-template {
    height: 100%;
    display: flex;
    flex-direction: column; }
    .nav-template .normal-header-container {
      position: fixed;
      z-index: 10;
      width: 100%; }
    .nav-template .a-left-navigation {
      position: fixed;
      top: 50px;
      height: 100%;
      display: inline-block;
      z-index: 5;
      background-color: #ffffff; }
    .nav-template .a-right-content {     
      display: flex;
      flex-direction: row; 
      width: 100%;
      height: 100%;
      z-index: 1; }
    .nav-template .a-footer-wrapper {
      padding: 10px 10px 10px 70px;
      text-align: center; }

      .footer {
        display: flex;
        justify-content: center;
        position: relative;
        left: 4px;
        bottom: 0;
        min-height: 1.5rem;
        height: 1.5rem;
        display: inline;     
        
        .ap-footer {
          display: flex;
          position: fixed;
          left: 4px;
          bottom: 0;
          flex-direction:row;
          justify-content: center;       
        }
      }

      #loader-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        backdrop-filter: blur(3px);
        
    }
    #loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 200px;
        height: 200px;
        margin: -50px 0 0 -50px;
    }

    .user-avatar {
      &:focus{
          border-radius: 50%!important;
          .keyboard-focus {
              outline: auto 5px -webkit-focus-ring-color;
              outline-offset: var(--spacing-1);
          }
      }
      &:focus:not(.keyboard-focus) {
          outline: none;
          box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
      }
  }

  .ap-buttons-wrapper{
    #groupdropdown {
      left: unset !important
    }
  }

