$small: 900px;
$medium: 1900px;
$large: 2560px;
$curved: 3440px;

.list-view-container {
  display: flex;
  z-index: 3;
  flex-direction: column;
  flex-grow: 2;
  vertical-align: text-top;
  margin-top: 55px;
  margin-right: 1rem;
  margin-bottom: auto;
  max-height: calc(100% - 1.5rem - 55px);

  background-color: var(--color-background-container);
  border-radius: var(--border-radius-3);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  letter-spacing: -0.025rem;
  padding: var(--spacing-2) var(--spacing-3);

  .list-pagination {
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 15%;

    .ap-field-wrapper {
      width: 80px;
      height: 45px;
    }
  }

  .list-view {
    @media screen and (min-width: $small) {
      tr {
        font-size: 12px;
      }

      .ap-dropdown.ListViewSelect.ap-dropdown-single {
        font-size: 12px;
      }

      .ap-table {
        position: relative;
        margin-top: auto;
        top: -25px !important;
        th {
          font-size: 13px;
        }
      }
    }

    @media screen and (min-width: $medium) {
      tr {
        font-size: 14px;
      }

      .ap-table {
        top: -25px !important;

        th {
          font-size: 14px;
        }
      }
    }
    @media screen and (min-width: $large) {
      tr {
        font-size: 16px;
      }
      .ap-table {
        top: -25px !important;

        th {
          font-size: 17px;
        }
      }
    }
    margin: 20px 10px 7px 10px;
    max-height: calc(100vh - 260px);
    overflow-y: scroll;

    .ap-table {
      th {
        top: -12px !important;
      }

      position: relative;

      border-radius: var(--border-radius-3);
      box-shadow: none !important;
    }

    .ap-list {
      width: 100%;
    }

    .ap-field-input {
      margin-top: 0.2rem !important;
      padding-bottom: unset !important;
    }

    .ListViewSelect {
      font-size: 14px;

    }

    .ap-dropdown.ListViewSelect.ap-dropdown-single {
      width: 70px;
      position: relative;
      margin: auto;
    }

    .ap-field-container {
      width: 70px;
      position: relative;
    }

    .ap-dropdown-list.ap-dropdown-list-default {
      width: max-content !important;
      position: absolute;
      left: 50%;
      margin-left: -30px;
    }
  }

  tr:focus-within {
    background-color: #c5c5c5 !important;
  }
}

th {
  width: 1000px;
  position: sticky !important;
  top: -56px;
  background: white;
  z-index: 2;
  margin: auto !important;
  text-align: center;
  width: fit-content !important;
}

.ap-field.text-readonly .ap-field-wrapper {
  cursor: pointer;
}

.ap-table table tbody tr td {
  vertical-align: middle;
  justify-items: center;

}

td {
  position: relative;
  width: 300px !important;
}
tr {
  cursor: pointer;
}

.ap-table table thead th .header-container.end-slot > * {
  justify-content: center;
  display: flex;
  margin: auto;
}
